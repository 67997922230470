import { starFull, starEmpty } from "../Utils/svg";
import { todayMonth, todayYear } from "../Utils/variables";
import "./index.scss";

export const displayNote = (note) => {
  const listNote = [1, 2, 3, 4, 5];
  const listStar = listNote.map((elem, i) => (
    <div key={i + "note"} alt={"étoile" + i} className="star">
      {elem <= note ? starFull : starEmpty}
    </div>
  ));
  return <div className="note-star">{listStar}</div>;
};

export const checkYearsInArray = (array, year) => {
  return array.find((elem) => elem.year === year);
};

export const checkYearInUrl = (year) => {
  if (year > todayYear || year < 2021 || isNaN(parseInt(year))) {
    return todayYear;
  }
  return year;
};

export const checkIfOpinionExist = (number, array) => {
  if (array.length > number) {
    return { message: "success", value: array[number] };
  }
  return { message: "error", value: "n'éxiste pas" };
};

export const scrollUpOrDown = (e, prevYValue) => {
  if (
    e.deltaY < 0 ||
    e.key === "ArrowUp" ||
    (e.changedTouches && e.changedTouches[0].clientY > prevYValue)
  ) {
    return { message: "success", value: "up" };
  }
  if (
    e.deltaY > 0 ||
    e.key === "ArrowDown" ||
    e === "down" ||
    (e.changedTouches && e.changedTouches[0].clientY < prevYValue)
  ) {
    return { message: "success", value: "down" };
  }
  return { message: "error", value: "nothing" };
};

export const verifiyName = (array, name) => {
  return array.find((elem) => name.toLowerCase().includes(elem.toLowerCase()));
};

export const checkLastYearAvailable = () => {
  if (todayMonth < 6) {
    return todayYear - 1;
  }
  return todayYear;
};
