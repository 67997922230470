import React, { Component } from "react";
import "./index.scss";
import finalLoading from "../../assets/finalLoading.svg";
import { Link } from "react-router-dom";

export default class LandingPage extends Component {
  render() {
    const { isLoading, classYear } = this.props;
    const title = "archiv.";
    const content = isLoading ? (
      <img src={finalLoading} alt="chargement" />
    ) : (
      <div className="error-content">
        <div className="strong-error">Erreur 404</div>
        {"\n"} Essaye de raffraichir la page
      </div>
    );

    return (
      <div className="all-opinions-page">
        {!isLoading && (
          <Link
            to="/"
            className={`title-all-opinion-page title-transition ${classYear}`}
          >
            {title}
          </Link>
        )}
        <div className="transition-content">{content}</div>
      </div>
    );
  }
}
