import React, { Component } from "react";
import { fullStarAdd, emptyStarAdd } from "../Utils/svg";
import { prohibitedName } from "../Utils/variables";
import { verifiyName } from "../Utils/globalFonction";
import gif from "../../assets/loadingBackgroundWhite.gif";

export default class addOpinionForm extends Component {
  state = {
    note: 0,
    noteHover: 0,
    listNote: [1, 2, 3, 4, 5],
    name: "",
    message: "",
    year: this.props.year,
    formIsSend: false,
    errorName: "le pseudo ne doit pas contenir plus de 12 caractères",
    errorMessage: "le texte ne doit pas contenir plus de 600 caractères",
    isNameValid: true,
  };

  verifiyNameValue(e) {
    const name = e.target.value;
    const res = verifiyName(prohibitedName, name);
    if (res === undefined) {
      return this.setState({
        name,
        errorName: "le pseudo ne doit pas contenir plus de 12 caractères",
        isNameValid: true,
      });
    }

    this.setState({
      name,
      errorName: "Votre nom d'utilisateurs n'est pas valide",
      isNameValid: false,
    });
  }

  submitInformation(e) {
    e.preventDefault();
    const { note, name, message, year } = this.state;

    const dataToSend = {
      note,
      name,
      message,
      year,
    };

    this.props.sendForm(dataToSend);

    this.setState({ formIsSend: true });
  }

  render() {
    const {
      listNote,
      name,
      message,
      note,
      noteHover,
      errorMessage,
      errorName,
      isNameValid,
    } = this.state;
    const { formIsSend, formIsLoadingToSend } = this.props;

    const allNote = listNote.map((elem, i) => (
      <div
        key={i + "note"}
        className="star"
        onClick={() => {
          this.setState({ note: elem, noteHover: elem });
        }}
        onMouseEnter={() => {
          this.setState({ noteHover: elem });
        }}
        onMouseLeave={() => {
          this.setState({ noteHover: note });
        }}
      >
        {elem <= noteHover ? fullStarAdd : emptyStarAdd}
      </div>
    ));

    const btn =
      name === "" ||
      message === "" ||
      note === 0 ||
      name.length > 12 ||
      message.length > 600 ||
      !isNameValid ? (
        <button className="btn-add-opinions-disabled" disabled>
          Envoyer votre avis
        </button>
      ) : (
        <button className="btn-add-opinions">Envoyer votre avis</button>
      );

    const classNameForm = formIsSend
      ? "form-add-opinion slide-send"
      : "form-add-opinion";

    return (
      <form
        autoComplete="off"
        className={classNameForm}
        onSubmit={(e) => {
          this.submitInformation(e);
        }}
      >
        {!formIsLoadingToSend ? (
          <>
            <div className="inputs-form">
              <div className="content-form all-note-form">{allNote}</div>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => {
                  this.verifiyNameValue(e);
                }}
                placeholder="Pseudo"
                className="content-form"
              />

              <textarea
                className="message-textarea"
                id="message"
                name="message"
                value={message}
                rows="8"
                onChange={(e) => {
                  this.setState({ message: e.target.value });
                }}
                placeholder="Description"
              />
            </div>
            <div className="error-input">
              <p
                className={
                  name.length > 12 || !isNameValid
                    ? "erreur-message-form "
                    : "erreur-message-form inactive "
                }
              >
                {errorName}
              </p>
              <p
                className={
                  message.trim().length > 600
                    ? "erreur-message-form"
                    : "erreur-message-form inactive"
                }
              >
                {errorMessage}
              </p>
            </div>
            <div className="btn-div">{btn}</div>
          </>
        ) : (
          <div className="gif-loading-form">
            <img src={gif} alt="chargement" />
          </div>
        )}
      </form>
    );
  }
}
