import "./App.css";
import AddOpinion from "./Components/AddOpinion/index";
import AllYears from "./Components/AllYears";
import LandingPage from "./Components/TransitionPage/index";
import { Route, Switch, HashRouter as Router } from "react-router-dom";
import PageOpinion from "./Components/AllOpinion/index";
import IdPage from "./Components/ShowOneOpinion/index";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={PageOpinion} />
          <Route exact path="/add" component={AddOpinion} />
          <Route exact path="/calendar" component={AllYears} />
          <Route exact path="/:year" component={PageOpinion} />
          <Route exact path="/article/" component={PageOpinion} />
          <Route exact path="/article/:id" component={IdPage} />
          <Route path="*" component={LandingPage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
