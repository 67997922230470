import React, { Component } from "react";
import { displayNote } from "../Utils/globalFonction";
import { goBack } from "../Utils/svg";
import "./css/index.scss";
import "./css/Transition.scss";

export default class SingleOpinion extends Component {
  render() {
    const { currentOpinion, showSingleOpinion, classPopUp, classIndex } =
      this.props;
    const { message, name, note } = currentOpinion;
    let newColor = "";
    const isSafari = window.safari !== undefined;

    switch (classIndex) {
      case "one-stars":
        newColor = "single-opinion-one-stars";
        break;
      case "two-stars":
        newColor = "single-opinion-two-stars";
        break;
      case "three-stars":
        newColor = "single-opinion-three-stars";
        break;
      case "four-stars":
        newColor = "single-opinion-four-stars";
        break;
      case "five-stars":
        newColor = "single-opinion-five-stars";
        break;
      default:
        break;
    }
    return (
      <div className={"single-opinion-page " + classPopUp + " " + newColor}>
        <div
          className={
            isSafari
              ? "cross-single-opinion for-safari"
              : "cross-single-opinion "
          }
          onClick={showSingleOpinion}
        >
          {goBack}
        </div>
        <div className="content-single-opinion">
          <p className="message-single-opinion">{currentOpinion && message}</p>
          <br />
        </div>
        <div className="info-comment-single-opinion">
          <p className="name-single-opinion">
            {currentOpinion && name.toUpperCase()}{" "}
          </p>
          <div className="note-single-opinion">
            {currentOpinion && displayNote(note)}
          </div>
        </div>
      </div>
    );
  }
}
