import React, { Component } from "react";
import { checkLastYearAvailable } from "../Utils/globalFonction";
import { arrowDown } from "../Utils/svg";

export default class PrincipalPage extends Component {
  render() {
    const {
      handleChange,
      classNamePrincipalPage,
      years,
      classElemPrincipalPage,
      classYearPrincipalPage,
      isLoading,
      isPrincipalPage,
    } = this.props;
    return (
      <div
        className={classNamePrincipalPage}
        onWheel={handleChange}
        onTouchEnd={handleChange}
      >
        <h1 className={`title-page ${classElemPrincipalPage}`}>archiv.</h1>
        <div className="body-principal-page  page-loading">
          {!isLoading && isPrincipalPage && (
            <>
              <div className={`principal-year ${classYearPrincipalPage}`}>
                {years}
              </div>

              <p className={`text-principal-page  ${classElemPrincipalPage}`}>
                Voici tout les commentaires sur l'année susmentionnée.
                Actuellement, vous pouvez laisser un avis sur:{" "}
                {checkLastYearAvailable()}
              </p>
            </>
          )}
        </div>
        <div
          className={`arrow-down-principal-page ${classElemPrincipalPage}`}
          onClick={() => handleChange("down")}
        >
          {arrowDown}
        </div>
      </div>
    );
  }
}
