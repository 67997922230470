import React, { Component } from "react";
import { cross } from "../Utils/svg";

export default class PopupError extends Component {
  closeAndChangeState() {
    const { closePopup, changeStateOnPopup, isCountEqualTen } = this.props;
    if (isCountEqualTen) {
      closePopup();
    }
    changeStateOnPopup(false);
  }

  render() {
    const { closePopup, changeStateOnPopup } = this.props;
    return (
      <div
        className="pop-up-error"
        onMouseOver={() => {
          changeStateOnPopup(true);
        }}
        onMouseOut={() => {
          this.closeAndChangeState();
        }}
      >
        <div className="cross-popup-error" onClick={() => closePopup()}>
          {cross}
        </div>
        <p>
          Une erreur est survenu, éssayez de desactiver votre bloqueur de pub.
          Si le problème persiste veuillez reéssayer plus tard.
        </p>
      </div>
    );
  }
}
