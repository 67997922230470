import React, { Component } from "react";
import {
  calendar,
  add,
  starHeaderEmpty,
  goBack,
  starHeaderFull,
} from "../Utils/svg";
import "./Css/index.scss";
import "./Css/Transition.scss";

export default class Header extends Component {
  state = {
    listNote: [1, 2, 3, 4, 5],
    note: 0,
  };

  sendNote(note) {
    this.setState({ note });
    this.props.sendNote(note);
  }

  render() {
    const { listNote, note } = this.state;
    const {
      isListStarVisible,
      location,
      redirectToOtherPage,
      classAnimation,
      isSingleCommentIsShowing,
    } = this.props;
    const singleStar = (
      <div
        className="filter-single-star"
        onClick={() => {
          this.setState({ isListStarVisible: true });
        }}
      >
        {starHeaderEmpty}
      </div>
    );

    const starList = listNote.map((elem, i) => (
      <div
        key={i + "note-filter"}
        className={"filter-star star-number-" + i}
        onMouseEnter={() => {
          this.sendNote(elem);
        }}
      >
        {elem <= note ? starHeaderFull : starHeaderEmpty}
      </div>
    ));

    const starFilter = isListStarVisible ? starList : singleStar;
    switch (location) {
      case "/add":
        return (
          <header className={`header add-header`}>
            {window.innerWidth > 1014 && (
              <div
                className="div-header-top calendar-icon"
                onClick={() => {
                  redirectToOtherPage("/calendar");
                }}
              >
                {calendar}
              </div>
            )}

            <div
              className="div-header-top"
              onClick={() => {
                redirectToOtherPage("goback");
              }}
            >
              {goBack}
            </div>
          </header>
        );
      case "/calendar":
        return (
          <header className={`header header-calendar ${classAnimation} `}>
            {window.innerWidth > 1014 && (
              <div
                className="div-header-top add-icon"
                onClick={() => {
                  redirectToOtherPage("add");
                }}
              >
                {add}
              </div>
            )}
            <div
              className="div-header-top"
              onClick={() => {
                redirectToOtherPage();
              }}
            >
              {goBack}
            </div>
          </header>
        );
      case "/article":
        return (
          <header className={`header ${classAnimation} `}>
            <div
              className="div-header-top "
              onClick={() => {
                redirectToOtherPage("/add");
              }}
            >
              {add}
            </div>
            <div
              className="div-header-top"
              onClick={() => {
                redirectToOtherPage("/calendar");
              }}
            >
              {calendar}
            </div>
          </header>
        );

      default:
        return (
          <header
            className={`header ${classAnimation} ${
              isSingleCommentIsShowing ? "behind" : "before"
            }`}
          >
            <div
              className="div-header-top calendar-header"
              onClick={() => {
                redirectToOtherPage("/calendar");
              }}
            >
              {calendar}
            </div>
            <div
              className="div-header-top add-page-header"
              onClick={() => {
                redirectToOtherPage("/add");
              }}
            >
              {add}
            </div>

            <div
              className="button-filter-note div-header-top "
              alt="bouton note"
              onMouseLeave={() => {
                this.setState({ note: 0 });
              }}
              onBlur={() => {
                this.setState({ isListStarVisible: false });
              }}
            >
              <div
                className={
                  isListStarVisible
                    ? "list-filter-note"
                    : "list-filter-note single-filter-note"
                }
              >
                {starFilter}
              </div>
            </div>
          </header>
        );
    }
  }
}
