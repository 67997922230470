import React, { Component } from "react";
import {
  getAllOpinions,
  getNumberOpinions,
  getNumberOpinionsByNote,
  getOpinionByNote,
} from "../../API/backFunctions";
import "./css/index.scss";
import "./css/TransitionScroll.scss";
import "./css/TransitionAll.scss";
import PrincipalPage from "./PrincipalPage";
import Header from "../Share/Header";
import AllOpinion from "./AllOpinion";
import LandingPage from "../TransitionPage/index";
import {
  checkLastYearAvailable,
  checkYearInUrl,
  scrollUpOrDown,
} from "../Utils/globalFonction";
import { checkIfNoteIsOk } from "./utils";
import Donate from "../Share/Donate";
import { errorMessage, emptyMessage, listColor } from "../Utils/variables";
import CountComs from "./CountComs.js";

class PageOpinion extends Component {
  state = {
    listOpinion: [],
    error: null,
    isLoading: true,
    isOpinionLoading: false,
    url: this.props.match.params.year || checkLastYearAvailable(),
    principalPageClassname: "principal-page",
    allOpinionClassname: "all-opinions-page",
    elemPrincipalPage: " page-loading ",
    yearPrincipalPage: "",
    yearOpinionPage: "invisible",
    number: 0,
    max: 10,
    isListStarVisible: false,
    note: 0,
    isComponentLoading: false,
    classIndex: "five-stars",
    starCurrentOpinion: 5,
    isLoadingToCalendar: false,
    isPrincipalPage: true,
    classAnimation: " page-loading",
    totalCom: 0,
    currentCom: 0,
    isSingleCommentIsShowing: false,
  };

  componentDidMount() {
    const { max, url } = this.state;
    const newUrl = checkYearInUrl(url);
    this.setState({ url: newUrl });
    getAllOpinions(newUrl, max)
      .then((res) => {
        if (res.status && res.status === "success") {
          if (res.result.length > 0) {
            this.setState({ listOpinion: res.result, isLoading: false });
          } else {
            this.setState({ listOpinion: emptyMessage, isLoading: false });
          }

          getNumberOpinions(url).then((res) => {
            this.setState({
              totalCom: res.result[0].count,
              currentCom: res.result[0].count === 0 ? 0 : 1,
            });
          });
        } else {
          this.setState({ listOpinion: errorMessage, isLoading: false });
        }
      })
      .catch((err) => {
        this.setState({
          error: err,
          isLoading: false,
          listOpinion: errorMessage,
        });
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { starCurrentOpinion } = this.state;
    if (prevState.starCurrentOpinion !== starCurrentOpinion) {
      this.setState({
        classIndex: listColor[starCurrentOpinion - 1],
      });
    }
  }

  handleChange(e, direction) {
    const { isComponentLoading, listOpinion, isLoading } = this.state;
    const prevYValue = 1000;
    const res = scrollUpOrDown(e, prevYValue);
    //direction peut etre egale a up pour monter.
    if (
      res.message === "success" &&
      res.value === "down" &&
      !isComponentLoading &&
      !isLoading //pas sur de ça
    ) {
      setTimeout(() => {
        setTimeout(() => {
          this.setState({ yearOpinionPage: "", isPrincipalPage: false });
        }, 500);
        this.setState({
          yearPrincipalPage: "year-animation-down",
          isComponentLoading: false,
          allOpinionClassname: "all-opinions-page slide-bottom-opinion",
        });
      }, 300);
      this.setState({
        elemPrincipalPage: "elem-animation-down",
        isComponentLoading: true,
      });
    }
    if (listOpinion.length > 0 && !isLoading) {
      this.setState({
        starCurrentOpinion: listOpinion[0].note,
        classIndex: listColor[listOpinion[0].note - 1],
      });
    }
  }

  showFilterNote(e) {
    const { note, url, max } = this.state;

    if (e.target.className === "list-filter-note single-filter-note") {
      return this.setState({ isListStarVisible: true });
    }

    const res = checkIfNoteIsOk(e.target.className);

    if (res) {
      this.setState({
        isLoading: true,
        starCurrentOpinion: 3,
      });
      getOpinionByNote(url, note, max)
        .then((res) => {
          if (res.status && res.status === "success") {
            if (res.result.length > 0) {
              this.setState({
                listOpinion: res.result,
                isLoading: false,
                starCurrentOpinion: note,
              });
            } else {
              const { name, title, message } = emptyMessage[0];
              const newEmptyMessage = [{ name, title, message, note: note }];
              this.setState({
                listOpinion: newEmptyMessage,
                isLoading: false,
                starCurrentOpinion: note,
              });
            }
            getNumberOpinionsByNote(url, note).then((data) => {
              this.setState({
                totalCom: data.result[0].count,
                currentCom: data.result[0].count === 0 ? 0 : 1,
              });
            });
          } else {
            const { name, title, message } = errorMessage[0];
            const newErrorMessage = [{ name, title, message, note: note }];
            this.setState({
              listOpinion: newErrorMessage,
              isLoading: false,
              starCurrentOpinion: note,
            });
          }
        })
        .catch((err) => {
          const { name, title, message } = errorMessage[0];
          const newErrorMessage = [{ name, title, message, note: note }];
          this.setState({
            error: err,
            isLoading: false,
            listOpinion: newErrorMessage,
          });
        });
    }
    this.setState({ isListStarVisible: false });
  }

  sendNote(note) {
    this.setState({ note });
  }

  redirectToOtherPage(destination) {
    const { allOpinionClassname, yearPrincipalPage } = this.state;
    setTimeout(() => {
      this.setState({ isLoadingToCalendar: false });
      if (destination === "/calendar" || destination === "/add") {
        this.props.history.push(destination);
      } else {
        this.props.history.goBack();
      }
    }, 500);
    this.setState({
      isLoadingToCalendar: true,
      allOpinionClassname: `redirect-to-calendar ${allOpinionClassname}`,
      yearPrincipalPage: `invisible  ${yearPrincipalPage}`,
      classAnimation: "redirect-to-calendar",
      starCurrentOpinion: 5,
    });
  }

  render() {
    const {
      isLoading,
      listOpinion,
      url,
      allOpinionClassname,
      principalPageClassname,
      number,
      isListStarVisible,
      max,
      note,
      yearPrincipalPage,
      elemPrincipalPage,
      yearOpinionPage,
      classIndex,
      isPrincipalPage,
      classAnimation,
      currentCom,
      totalCom,
      isSingleCommentIsShowing,
    } = this.state;

    if (
      window.innerHeight < 400 &&
      window.matchMedia("(orientation: landscape)").matches
    ) {
      return (
        <div className="error-orientation">
          <div className="landscape-mode">
            Repasse en mode portrait et raffraichi la page stpl
          </div>
        </div>
      );
    }

    return (
      <div
        onClick={(e) => {
          this.showFilterNote(e);
        }}
        className={`index-opinion-page ${classIndex}`}
      >
        {!isPrincipalPage && (
          <Header
            year={url}
            isListStarVisible={isListStarVisible}
            note={note}
            sendNote={(noteFiltrer) => {
              this.sendNote(noteFiltrer);
            }}
            redirectToOtherPage={(destination) => {
              this.redirectToOtherPage(destination);
            }}
            classAnimation={classAnimation}
            isSingleCommentIsShowing={isSingleCommentIsShowing}
          />
        )}
        {!isPrincipalPage && (
          <CountComs
            totalCom={totalCom}
            currentCom={currentCom}
            isSingleCommentIsShowing={isSingleCommentIsShowing}
          />
        )}
        {!isPrincipalPage && <Donate />}

        <PrincipalPage
          years={url}
          classNamePrincipalPage={principalPageClassname}
          handleChange={(e) => {
            this.handleChange(e);
          }}
          classElemPrincipalPage={elemPrincipalPage}
          classYearPrincipalPage={yearPrincipalPage}
          isLoading={isLoading}
          isPrincipalPage={isPrincipalPage}
        />
        {isLoading ? (
          <LandingPage
            year={url}
            classNameOp={allOpinionClassname}
            isLoading={isLoading}
            classYear={yearOpinionPage}
          />
        ) : (
          <AllOpinion
            listOpinion={listOpinion}
            year={url}
            classNameOp={allOpinionClassname}
            number={number}
            handleChange={(e) => {
              this.handleChange(e, "top");
            }}
            max={max}
            note={note}
            classYear={yearOpinionPage}
            changeStarValue={(note) => {
              this.setState({ starCurrentOpinion: note });
            }}
            classIndex={classIndex}
            changeCurrentNumber={(direction) => {
              if (direction === "add") {
                currentCom < totalCom &&
                  this.setState({
                    currentCom: currentCom + 1,
                  });
              } else {
                currentCom > 1 &&
                  this.setState({
                    currentCom: currentCom - 1,
                  });
              }
            }}
            toggleSingleComments={() => {
              this.setState({
                isSingleCommentIsShowing: !isSingleCommentIsShowing,
              });
            }}
          />
        )}
      </div>
    );
  }
}

export default PageOpinion;
