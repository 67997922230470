import axios from "axios";

// const ENDPOINT_BASE = "http://localhost:8080/api/v1";
const ENDPOINT_BASE = "https://archiv.fr/app";
const ENDPOINT_AVIS = ENDPOINT_BASE + "/avis";
const ENDPOINT_YEARS = ENDPOINT_BASE + "/years";

export const getAllOpinions = async (year, max) => {
  try {
    const response = await axios({
      method: "get",
      url: ENDPOINT_AVIS + "/getAllOpinion/" + year + "/" + max,
      data: {},
    });
    return response.data;
  } catch (err) {
    console.log("error page requete : ", err);
  }
};

export const getOpinionByNote = async (year, note, max) => {
  try {
    const response = await axios({
      method: "get",
      url: ENDPOINT_AVIS + "/getByNote/" + year + "/" + note + "/" + max,
      data: {},
    });
    return response.data;
  } catch (err) {
    console.log("error requete : ", err);
  }
};

export const getOpinionById = async (id) => {
  try {
    const response = await axios({
      method: "get",
      url: ENDPOINT_AVIS + "/getById/" + id,
      data: {},
    });
    return response.data;
  } catch (err) {
    console.log("error requete : ", err);
  }
};

export const getNumberOpinions = async (year) => {
  try {
    const response = await axios({
      method: "get",
      url: ENDPOINT_AVIS + "/getNumberOpinions/" + year,
      data: {},
    });
    return response.data;
  } catch (err) {
    console.log("error requete get number opinion : ", err);
  }
};
export const getNumberOpinionsByNote = async (year, note) => {
  try {
    const response = await axios({
      method: "get",
      url: ENDPOINT_AVIS + "/getNumberOpinionsByNote/" + year + "/" + note,
      data: {},
    });
    return response.data;
  } catch (err) {
    console.log("error requete get number opinion : ", err);
  }
};

export const addOpinion = async (data) => {
  try {
    const response = await axios({
      method: "post",
      url: ENDPOINT_AVIS,
      data,
    });
    return response.data;
  } catch (err) {
    console.log("error requete : ", err);
  }
};

export const getAllYears = async () => {
  try {
    const response = await axios({
      method: "get",
      url: ENDPOINT_YEARS,
      data: {},
    });
    return response.data;
  } catch (err) {
    console.log("error requete : ", err);
  }
};
