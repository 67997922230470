// export const getApi = async () => {
//   let xmlhttp = false;
//   if (window.XMLHttpRequest) {
//     xmlhttp = new XMLHttpRequest();

//     try {
//       await xmlhttp.open(
//         "GET",
//         "http://ip-api.com/json/?fields=country",
//         false
//       );
//       xmlhttp.send();
//       const reponse = xmlhttp.responseText;
//       console.log("resss ", reponse);
//       const country = JSON.parse(reponse).country;
//       return country;
//     } catch (err) {
//       console.log("err", err);
//     }
//   }
// };

// import axios from "axios";
// const ENDPOINT = "http://ip-api.com/json/?fields=country";
// export const getApi = async () => {
//   try {
//     const response = await axios({
//       method: "get",
//       url: ENDPOINT,
//     });
//     console.log(response.data.country);
//   } catch (err) {
//     console.log("error page requete : ", err);
//     return err;
//   }
// };

// import axios from "axios";
// const apiKey = "7267ab13476e58943abd627093964e58";
// const ENDPOINT = "http://api.ipapi.com/api/161.185.160.93?access_key=";
// const okok = ENDPOINT + apiKey;
// export const getApi = async () => {
//   console.log(okok);

//   try {
//     const response = await axios({
//       method: "get",
//       url: okok,
//       data: {},
//       responseType: JSON,
//     });
//   } catch (err) {
//     console.log("error page requete : ", err);
//     return err;
//   }
// };

import axios from "axios";
const apiKey = "f9902210-97f0-11eb-a459-b997d30983f1";
const ENDPOINT_BASE = "https://geolocation-db.com/json/";
const ENDPOINT_API = ENDPOINT_BASE + apiKey;
export const getApi = async () => {
  try {
    const response = await axios({
      method: "get",
      url: ENDPOINT_API,
      responseType: JSON,
    });
    return response.data.country_name;
  } catch (err) {
    console.log("error page requete : ", err);
    return "no-country";
  }
};
