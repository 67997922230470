import React, { Component } from "react";
import { logoReddit, logoTwitter, logoFacebook } from "../Utils/svg";
import {
  FacebookShareButton,
  TwitterShareButton,
  RedditShareButton,
} from "react-share";

export default class AfterSendMessage extends Component {
  state = {
    url: "https://archiv.fr",
    message: "Viens voir mon avis sur l'année " + this.props.year,
  };
  render() {
    const { classNameAfterSend } = this.props;
    const { url, message } = this.state;
    return (
      <div className={classNameAfterSend}>
        <div className="component-after-send">
          <div className="thank-message">
            Merci d'avoir participé ! <br /> N'hésite pas à partager
          </div>
          <div className="share-logo">
            <TwitterShareButton url={url} title={message}>
              <div className="logo-twitter">{logoTwitter}</div>
            </TwitterShareButton>
            <RedditShareButton url={url} title={message}>
              <div className="logo-insta empty-svg">{logoReddit}</div>
            </RedditShareButton>
            <FacebookShareButton url={url} quote={message}>
              <div className="logo-facebook empty-svg">{logoFacebook}</div>
            </FacebookShareButton>
          </div>
        </div>
        <div className="confirm-send"> Bien Envoyé </div>
      </div>
    );
  }
}
