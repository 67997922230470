import React, { Component } from "react";
import { displayNote } from "../Utils/globalFonction";

export default class Opinion extends Component {
  render() {
    const { currentOpinion, classElem, showSingleOpinion } = this.props;
    let newMessage = false;
    if (
      window.innerWidth < 600 &&
      currentOpinion &&
      currentOpinion.message.length > 150
    ) {
      newMessage = currentOpinion.message.slice(0, 150) + "...";
    }

    return (
      <li className={classElem}>
        <div className="opinion-message">
          <p>
            {currentOpinion !== undefined ? (
              <>
                <span className="title-opinion">{currentOpinion.title}</span>
                <br />
              </>
            ) : (
              ""
            )}
            {currentOpinion !== undefined
              ? newMessage
                ? newMessage
                : currentOpinion.message
              : ""}
          </p>
          {currentOpinion !== undefined
            ? newMessage && (
                <div className="show-more" onClick={showSingleOpinion}>
                  Afficher plus
                </div>
              )
            : ""}
        </div>
        <div className="opinion-information">
          <div className="opinon-information-elem">
            {currentOpinion && currentOpinion.name.toUpperCase()}
          </div>
          <div className="opinon-information-elem">
            {currentOpinion !== "" &&
              currentOpinion !== undefined &&
              displayNote(currentOpinion.note)}
          </div>
        </div>
      </li>
    );
  }
}
