import React, { Component } from "react";
import "./Css/Transition.scss";
import "./Css/index.scss";
// import { don }  from "../Utils/svg";

export default class Donate extends Component {
  render() {
    /* return (
      <form
        action="https://www.paypal.com/donate"
        method="post"
        target="_top"
        className="don"
      >
        <input type="hidden" name="hosted_button_id" value="SLZAP63ASFW3C" />
        <label
          onMouseEnter={() => {
            this.setState({ isPopUpVisible: true });
          }}
          onMouseLeave={() => {
            this.setState({ isPopUpVisible: false });
          }}
        >
          <input
            type="submit"
            border="0"
            name="submit"
            title="PayPal - The safer, easier way to pay online!"
            alt="Donate with PayPal button"
          />
          {don}
        </label>
      </form>
    );
    */
    return (
      <div className="contact page-loading">
        <a
          href={`mailto:support@archiv.fr ?subject= Problème sur le site
          &body=Merci de nous transmettre les informations suivantes:
           1) Votre navigateur(Mozilla, chrome,safari,...) 
           2) Le type d'apareil (ordinateur portable, iphone 11pro,tablette samsung,...)
           3) Le problème rencontré.
           Nous accordons une grande importance à la maintenance de notre site et faisons tout pour qu'il soit 
           le plus simple d'utilisation, n'hésitez donc pas si vous avez des suggestions qui pourraient l'ameliorer!
           Merci. `}
        >
          Contactez nous ici
        </a>
      </div>
    );
  }
}
