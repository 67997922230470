import React, { Component } from "react";
import { getAllYears } from "../../API/backFunctions";
import "./css/index.scss";
import "./css/Transition.scss";
import ListYears from "./ListYears";
import LandingPage from "../TransitionPage/index";
import Donate from "../Share/Donate";
import Header from "../Share/Header";
import { checkLastYearAvailable } from "../Utils/globalFonction";

export default class AllYears extends Component {
  state = {
    listYears: [],
    isLoading: true,
    error: false,
    classCalendar: "calendar-page",
    animationScroll: "",
    titleClass: "title-link",
  };

  componentDidMount() {
    getAllYears()
      .then((res) => {
        if (res.status === "success")
          this.setState({ listYears: res.result, isLoading: false });
        else this.setState({ error: true, isLoading: false });
      })
      .catch((err) => {
        this.setState({ error: true, isLoading: false });
      });
  }

  redirectToOtherPage = (destination) => {
    const { goBack, push } = this.props.history;
    setTimeout(() => {
      if (destination) {
        return push("/" + destination);
      }
      goBack();
    }, 1000);
    this.setState({
      animationScroll: "change-scroll",
      titleClass: "title-link-leave",
    });
  };

  render() {
    const {
      isLoading,
      error,
      listYears,
      classCalendar,
      animationScroll,
      titleClass,
    } = this.state;
    const { location } = this.props;

    if (isLoading) {
      return (
        <LandingPage
          isLoading={isLoading}
          redirectToOtherPage={() => this.redirectToOtherPage()}
        />
      );
    }
    if (error) {
      return (
        <LandingPage redirectToOtherPage={() => this.redirectToOtherPage()} />
      );
    }

    return (
      <div className={classCalendar}>
        <div className="div-flou-top"></div>
        <Header
          location={location.pathname}
          redirectToOtherPage={(destination) => {
            this.redirectToOtherPage(destination);
          }}
          classAnimation={animationScroll}
        />
        <div
          className={titleClass}
          onClick={() => {
            this.redirectToOtherPage(checkLastYearAvailable());
          }}
        >
          archiv.
        </div>
        <div className="calendar-page-content">
          <Donate />
          <ListYears
            listYears={listYears}
            redirectToOpinion={(year) => {
              this.redirectToOtherPage(year);
            }}
            animationScroll={animationScroll}
          />
        </div>
        <div className="div-flou-down"></div>
      </div>
    );
  }
}
//
