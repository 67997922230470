import React, { Component } from "react";
import Opinion from "./Opinion";
import { scrollUpOrDown } from "../Utils/globalFonction";
import { changeScrollClass } from "./utils";
import { getAllOpinions, getOpinionByNote } from "../../API/backFunctions";
import SingleOpinion from "../SingleOpinion/index";

export default class AllOpinion extends Component {
  state = {
    listOpinion: this.props.listOpinion,
    title: this.props.year,
    prevPrevOpinion: "",
    prevOpinion: "",
    currentOpinion: this.props.listOpinion[0],
    nextOpinion: this.props.listOpinion[1] || "",
    nextNextOpinion: this.props.listOpinion[2] || "",
    number: 0,
    isLastOpinion: false,
    isFirstOpinion: true,
    isScroll: false,
    prevYValue: "",
    scrollDirection: "",
    max: this.props.max,
    isLoading: false,
    error: "",
    isSingleOpinionVisible: "",
    note: this.props.note,
    classPopUp: "",
  };

  componentDidUpdate(prevProps, prevState) {
    const { listOpinion, number, isLastOpinion, currentOpinion } = this.state;
    const { changeStarValue } = this.props;
    if (prevState.listOpinion.length > listOpinion.length) {
      this.setState({ nextNextOpinion: listOpinion[number + 2] });
    }

    if (listOpinion.length === 1 && !isLastOpinion) {
      this.setState({ isLastOpinion: true });
    }

    if (prevState.currentOpinion !== currentOpinion) {
      changeStarValue(currentOpinion.note);
    }
  }

  showSingleOpinion() {
    const { isSingleOpinionVisible } = this.state;
    const { toggleSingleComments } = this.props;
    if (isSingleOpinionVisible) {
      setTimeout(() => {
        this.setState({ classPopUp: "" });
        toggleSingleComments();
      }, 500);
      this.setState({
        isSingleOpinionVisible: false,
        classPopUp: "slide-popup-bottom",
      });
    } else {
      this.setState({
        isSingleOpinionVisible: true,
        classPopUp: "slide-popup-top",
      });
      toggleSingleComments();
    }
  }

  updateListOpinion() {
    const { max, title, note } = this.state;
    this.setState({ isLoading: true });

    if (note === 0) {
      getAllOpinions(title, max + 5).then((res) => {
        if (res.status && res.status === "success") {
          this.setState({
            listOpinion: res.result,
            isLoading: false,
            max: max + 5,
          });
        } else {
          this.setState({ error: res.message, isLoading: false });
        }
      });
    } else {
      getOpinionByNote(title, note, max + 5).then((res) => {
        if (res.status && res.status === "success") {
          this.setState({
            listOpinion: res.result,
            isLoading: false,
            max: max + 5,
          });
        } else {
          this.setState({ error: res.message, isLoading: false });
        }
      });
    }
  }

  showNextOpinion() {
    const { number, listOpinion, isLastOpinion, isSingleOpinionVisible } =
      this.state;
    if (isSingleOpinionVisible) {
      return;
    }

    this.props.changeCurrentNumber("add");
    if (number + 1 === listOpinion.length - 1 && !isLastOpinion) {
      this.setState({
        prevPrevOpinion: listOpinion[number - 1],
        prevOpinion: listOpinion[number],
        currentOpinion: listOpinion[number + 1],
        nextOpinion: "",
        nextNextOpinion: "",
        isLastOpinion: true,
        number: number + 1,
        isFirstOpinion: false,
      });

      return;
    }

    if (number + 2 === listOpinion.length - 1) {
      this.updateListOpinion();
      this.setState({
        prevPrevOpinion: listOpinion[number - 1],
        prevOpinion: listOpinion[number],
        currentOpinion: listOpinion[number + 1],
        nextOpinion: listOpinion[number + 2],
        nextNextOpinion: "",
        number: number + 1,
        isFirstOpinion: false,
      });

      return;
    }

    if (listOpinion.length > number + 3) {
      this.setState({
        prevPrevOpinion: listOpinion[number - 1],
        prevOpinion: listOpinion[number],
        currentOpinion: listOpinion[number + 1],
        nextOpinion: listOpinion[number + 2],
        nextNextOpinion: listOpinion[number + 3],
        number: number + 1,
        isFirstOpinion: false,
        isLastOpinion: false,
      });

      return;
    }
  }

  showPrevOpinion() {
    const { number, listOpinion, isFirstOpinion, isSingleOpinionVisible } =
      this.state;
    if (isSingleOpinionVisible) {
      return;
    }
    this.props.changeCurrentNumber("less");

    if (number - 1 === 0 && !isFirstOpinion) {
      this.setState({
        prevOpinion: "",
        currentOpinion: listOpinion[0],
        nextOpinion: listOpinion[number],
        nextNextOpinion: listOpinion[number + 1],
        isFirstOpinion: true,
        isLastOpinion: false,
        number: number - 1,
      });
      return;
    } else if (isFirstOpinion) {
      return;
    }

    if (number - 2 === 0) {
      this.setState({
        prevPrevOpinion: "",
        prevOpinion: listOpinion[0],
        currentOpinion: listOpinion[number - 1],
        nextOpinion: listOpinion[number],
        nextNextOpinion: listOpinion[number + 1],
        number: number - 1,
        isLastOpinion: false,
      });

      return;
    }

    if (listOpinion.length > 0) {
      this.setState({
        prevPrevOpinion: listOpinion[number - 3],
        prevOpinion: listOpinion[number - 2],
        currentOpinion: listOpinion[number - 1],
        nextOpinion: listOpinion[number],
        nextNextOpinion: listOpinion[number + 1],
        number: number - 1,
        isLastOpinion: false,
      });
      return;
    }
  }

  scrollPage(e) {
    const { isScroll, prevYValue, isSingleOpinionVisible } = this.state;

    if (!isScroll && !isSingleOpinionVisible) {
      const res = scrollUpOrDown(e, prevYValue);
      if (res.message === "success") {
        setTimeout(() => {
          if (res.value === "down") {
            this.showNextOpinion();
          }
          if (res.value === "up") {
            this.showPrevOpinion();
          }

          this.setState({ isScroll: false, scrollDirection: "" });
        }, 500);

        this.setState({ isScroll: true, scrollDirection: res.value });
      }
    }
  }

  render() {
    const {
      title,
      prevOpinion,
      currentOpinion,
      nextOpinion,
      isScroll,
      scrollDirection,
      prevPrevOpinion,
      nextNextOpinion,
      isLastOpinion,
      isFirstOpinion,
      isSingleOpinionVisible,
      classPopUp,
    } = this.state;

    const { classNameOp, handleChange, classYear, classIndex } = this.props;

    const res = changeScrollClass(
      isScroll,
      scrollDirection,
      isLastOpinion,
      isFirstOpinion
    );

    let { classScrollCurrent, classScrollNext, classScrollPrev } = res;

    if (isSingleOpinionVisible) {
      classScrollCurrent = classScrollCurrent + " animation-scroll-current";
    }

    return (
      <div
        className={classNameOp}
        onWheel={(e) => {
          this.scrollPage(e);
        }}
        onKeyDown={(e) => {
          this.scrollPage(e);
        }}
        tabIndex={0}
        onTouchStart={(e) => {
          this.setState({ prevYValue: e.changedTouches[0].clientY });
        }}
        onTouchEnd={(e) => {
          this.scrollPage(e);
        }}
      >
        <h2
          className={`title-all-opinion-page ${classYear}`}
          onClick={handleChange}
        >
          {title}
        </h2>
        <div className="div-list-opinions">
          <ul className="list-opinions">
            <Opinion
              currentOpinion={prevPrevOpinion}
              classElem={"next-opinion" + classScrollNext}
            />
            <Opinion
              currentOpinion={prevOpinion}
              classElem={"prev-opinion" + classScrollPrev}
            />
            <Opinion
              currentOpinion={currentOpinion}
              classElem={"current-opinion" + classScrollCurrent}
              showSingleOpinion={() => {
                this.showSingleOpinion();
              }}
            />
            <Opinion
              currentOpinion={nextOpinion}
              classElem={"next-opinion" + classScrollNext}
            />
            <Opinion
              currentOpinion={nextNextOpinion}
              classElem={"prev-opinion" + classScrollPrev}
            />
          </ul>
        </div>
        <SingleOpinion
          currentOpinion={currentOpinion}
          isVisible={isSingleOpinionVisible}
          classPopUp={classPopUp}
          showSingleOpinion={() => {
            this.showSingleOpinion();
          }}
          classIndex={classIndex}
        />
      </div>
    );
  }
}
