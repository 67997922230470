import React, { Component } from "react";
import "./css/index.scss";
import "./css/transition.scss";
import Header from "../Share/Header";
import AddOpinionForm from "./PostOpinionForm";
import { addOpinion } from "../../API/backFunctions";
import Donate from "../Share/Donate";
import AfterSendMessage from "./AfterSendMessage";
import PopupError from "./PopupError";
import { getApi } from "../../API/ipApi";
import { checkLastYearAvailable } from "../Utils/globalFonction";

export class AddOpinion extends Component {
  state = {
    year: checkLastYearAvailable(),
    formIsSend: false,
    error: false,
    formIsLoadingToSend: false,
    classAddPage: "",
    isMouseOnPopup: false,
    isCountEqualTen: false,
  };

  sendForm(data) {
    this.setState({
      formIsLoadingToSend: true,
    });
    getApi()
      .then((country) => {
        const { note, name, message, year } = data;
        const newData = {
          note,
          name,
          message,
          year,
          country,
        };
        addOpinion(newData)
          .then((res, reject) => {
            if (res.status === "success")
              this.setState({ formIsSend: true, formIsLoadingToSend: false });
            else {
              reject("erreur d'envoi");
            }
          })
          .catch((err) => {
            setTimeout(() => {
              const { isMouseOnPopup } = this.state;
              if (!isMouseOnPopup) {
                this.setState({ error: false });
              } else {
                this.setState({ isCountEqualTen: true });
              }
            }, 10000);
            this.setState({ error: true, formIsLoadingToSend: false });
          });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  redirectToOtherPage = (destination) => {
    //const { allOpinionClassname, yearPrincipalPage } = this.state;
    setTimeout(() => {
      if (destination === "/calendar" || destination === "/") {
        this.props.history.push(destination);
      } else {
        this.props.history.goBack();
      }
    }, 500);
    this.setState({
      classAddPage: "change-page",
    });
  };

  render() {
    const {
      year,
      formIsSend,
      error,
      formIsLoadingToSend,
      classAddPage,
      isCountEqualTen,
    } = this.state;
    const { location } = this.props;

    const classNameAfterSend = formIsSend
      ? "after-send show-component-after-send"
      : "after-send";
    return (
      <div className={`add-page ${classAddPage}`}>
        <Header
          location={location.pathname}
          redirectToOtherPage={this.redirectToOtherPage}
        />
        {window.innerWidth > 800 && <Donate />}
        <div className="add-page-content">
          <div
            className="title-link"
            onClick={() => {
              this.redirectToOtherPage("/");
            }}
          >
            archiv.
          </div>
          <AddOpinionForm
            year={year}
            formIsSend={formIsSend}
            sendForm={(data) => {
              this.sendForm(data);
            }}
            formIsLoadingToSend={formIsLoadingToSend}
          />
          <AfterSendMessage
            classNameAfterSend={classNameAfterSend}
            year={year}
          />
        </div>

        {error && (
          <PopupError
            closePopup={() =>
              this.setState({ error: false, isCountEqualTen: false })
            }
            changeStateOnPopup={(res) => {
              this.setState({ isMouseOnPopup: res });
            }}
            isCountEqualTen={isCountEqualTen}
          />
        )}
      </div>
    );
  }
}

export default AddOpinion;
