export const todayYear = new Date().getFullYear();
export const todayMonth = new Date().getMonth();

export const errorMessage = [
  {
    name: "admin",
    title: "Erreur 404",
    message:
      "Essaie de refresh ta page en appuyant sur F5. Si ça ne fonctionne pas regarde si tu as toujours accès à internet :).",
    note: 1,
  },
];
export const emptyMessage = [
  {
    name: "admin",
    title: "Liste vide..",
    message: "Il n'y a pas encore d'avis ici.",
    note: 1,
  },
];
export const listColor = [
  "one-stars",
  "two-stars",
  "three-stars",
  "four-stars",
  "five-stars",
];

export const prohibitedName = ["admin", "4dm1n", "adm1n", "4dmin"];
