import React, { Component } from "react";

export default class countComs extends Component {
  render() {
    const { totalCom, currentCom, isSingleCommentIsShowing } = this.props;

    return (
      <div
        className={`number-comments page-loading ${
          isSingleCommentIsShowing ? "behind" : "before"
        } `}
      >
        {currentCom}/{totalCom}
      </div>
    );
  }
}
