import React, { Component } from "react";
import { getOpinionById } from "../../API/backFunctions";
import Header from "../Share/Header";
import LandingPage from "../TransitionPage";
import { displayNote } from "../Utils/globalFonction";
import { listColor } from "../Utils/variables";
import "./style.scss";

export default class IdPage extends Component {
  state = {
    isLoading: true,
    opinion: [],
    error: false,
    classPage: "page-loading",
    classByColor: "",
  };

  componentDidMount() {
    let id = this.props.match.params.id;
    const { push } = this.props.history;

    id = parseInt(id);

    if (isNaN(id)) push("/");
    else {
      getOpinionById(id)
        .then((res) => {
          if (res.result.length === 0) push("/");
          else {
            this.setState({
              opinion: res.result[0],
              isLoading: false,
              classByColor: listColor[res.result[0].note - 1],
            });
          }
        })
        .catch((err) => {
          this.setState({ error: true, isLoading: false });
        });
    }
  }

  redirectToOtherPage = (destination) => {
    //const { allOpinionClassname, yearPrincipalPage } = this.state;
    setTimeout(() => {
      if (destination) {
        this.props.history.push(destination);
      }
    }, 500);
    this.setState({
      classPage: "change-page",
    });
  };

  render() {
    const { opinion, isLoading, classPage, classByColor } = this.state;
    const { name, note, message, year } = opinion;
    if (isLoading) return <LandingPage isLoading={true} />;

    return (
      <div
        className={`all-opinions-page page-single-opinion ${classByColor} ${classPage}`}
      >
        <Header
          location={"/article"}
          isListStarVisible={false}
          redirectToOtherPage={this.redirectToOtherPage}
          // classAnimation={classAnimation}
        />
        <h1
          className="title-all-opinion-page"
          onClick={() => {
            this.redirectToOtherPage(`/${year}`);
          }}
        >
          {year}
        </h1>
        <div className="content-page-single-opinion">
          <div className="one-opinion-article">
            <div className="opinion-message">
              <p>{message}</p>
            </div>
            <div className="opinion-information">
              <div className="opinon-information-elem">{name}</div>
              <div className="opinon-information-elem">{displayNote(note)}</div>
            </div>
          </div>
          <div className="btn-div-single-opinion">
            <button
              className="btn-add-opinions"
              onClick={() => {
                this.redirectToOtherPage(`/${year}`);
              }}
            >
              Voir les autres avis
            </button>
          </div>
        </div>
      </div>
    );
  }
}
