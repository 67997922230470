import React, { Component } from "react";
import { checkLastYearAvailable, displayNote } from "../Utils/globalFonction";

export default class ListYears extends Component {
  state = {
    listYears: this.props.listYears,
  };

  render() {
    const { listYears } = this.state;
    const { animationScroll, redirectToOpinion } = this.props;

    const allYears = listYears.map((elem, i) => {
      const { year, note } = elem;
      if (year <= checkLastYearAvailable()) {
        let classElem = i === 0 ? `year first-year ` : "year ";

        return (
          <li
            className={classElem}
            key={"year " + i}
            onClick={() => {
              redirectToOpinion(year);
            }}
          >
            <div className="elem-year"> {year}</div>
            <div className={`all-note `}>{displayNote(note)}</div>
          </li>
        );
      } else {
        let classFuturYear =
          i === listYears.length - 1 ? "year-futur last-year " : "year-futur ";
        return (
          <li className={classFuturYear} key={"year " + i}>
            <div className="elem-year"> {year}</div>
            <div className="all-note note-desabled">{displayNote(null)}</div>
          </li>
        );
      }
    });

    return (
      <ul className={`all-years-content ${animationScroll}`}>{allYears}</ul>
    );
  }
}
