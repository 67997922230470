export const changeScrollClass = (
  isScroll,
  scrollDirection,
  isLastOpinion,
  isFirstOpinion
) => {
  let classScrollCurrent = "";
  let classScrollNext = "";
  let classScrollPrev = "";
  if (isScroll) {
    switch (scrollDirection) {
      case "down":
        if (isLastOpinion) {
          classScrollCurrent = " slide-top-for-current-last";
          classScrollNext = " slide-top-for-next-last";
          classScrollPrev = " slide-top-for-prev-last";
        } else {
          classScrollCurrent = " slide-top-for-current";
          classScrollNext = " slide-top-for-next";
          classScrollPrev = " slide-top-for-prev";
        }
        break;
      case "up":
        if (isFirstOpinion) {
          classScrollCurrent = " slide-bottom-for-current-first";
          classScrollNext = " slide-bottom-for-next-first";
          classScrollPrev = " slide-bottom-for-prev-first";
        } else {
          classScrollCurrent = " slide-bottom-for-current";
          classScrollNext = " slide-bottom-for-next";
          classScrollPrev = " slide-bottom-for-prev";
        }
        break;
      default:
        classScrollCurrent = " ";
        classScrollNext = " ";
        classScrollPrev = " ";
        break;
    }
  }

  let response = { classScrollCurrent, classScrollNext, classScrollPrev };

  return response;
};

export const checkIfNoteIsOk = (target) => {
  const array = [
    "filter-star star-number-0",
    "filter-star star-number-1",
    "filter-star star-number-2",
    "filter-star star-number-3",
    "filter-star star-number-4",
  ];

  const res = array.filter((elem) => elem === target);

  if (res.length > 0) return true;
  else return false;
};
